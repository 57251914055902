import { json as jsonColors } from "@codemirror/lang-json";
import { EditorView } from '@codemirror/view';
import { basicSetup } from 'codemirror';

enum HttpCode {
    OK = 200,
    NO_CONTENT = 204,
    BAD_REQUEST = 400,
    UNAUTHORIZED = 401,
    FORBIDDEN = 403,
    NOT_FOUND = 404,
    INTERNAL_SERVER_ERROR = 500,
}

interface GenerateReqParams {
    url: string;
    topic?: string;
    mainKeyword?: string;
    keywords?: string[];
    keywordsLocation?: string;
    outline?: string;
    // outputLength?: ArticleLength;
    outputLanguage?: string;
    postbackUrl?: string;
}

enum ArticleLength {
    Short = "short",
    Medium = "medium",
    Long = "long",
}

interface GenerateArticleResDTO {
    url: string;
    topic: string;
    // outline: string;
    keywords: string[];
    content: string;
    imagePrompt: string;
    wordCount: number;
}

const hamburgerBtn = document.querySelector('.js-hamburger-btn');
const navMenuElement = document.querySelector('.js-nav-menu');

hamburgerBtn?.addEventListener('click', () => {
    if (navMenuElement?.classList.contains('is-open')) {
        navMenuElement?.classList.toggle('is-open');

        return;
    }

    if (!navMenuElement?.classList.contains('is-open')) {
        navMenuElement?.setAttribute("data-nav-menu-open", "");
        navMenuElement?.classList.toggle('is-open');

        return;
    }
});

const PROD_HOST_FRONTEND = 'dashboard.hemiro.com'
const PROD_HOST_BACKEND = 'api.hemiro.com';
const DEV_HOST_FRONTEND = 'dev-dashboard.hemiro.com'
const DEV_HOST_BACKEND = 'dev-api.hemiro.com';

const isProduction = window.location.hostname === PROD_HOST_FRONTEND;
const isDev = window.location.hostname === DEV_HOST_FRONTEND;

let apiUrl;

if (isProduction) {
    apiUrl = `https://${PROD_HOST_BACKEND}`;
} else if (isDev) {
    apiUrl = `https://${DEV_HOST_BACKEND}`;
} else {
    apiUrl = `${window.location.href.slice(0, -1)}/api`;
}

const isLocalhost = window.location.hostname === 'localhost';

const editorElement = document.querySelector('.js-ck-editor') as HTMLElement;

const apiFlowBtn = document.querySelector('.js-api-flow-btn');

const generateImageBtn = document.querySelector('.js-generate-image-btn');
const generateArticleBtn = document.querySelector('.js-generate-article-btn');
const urlInputElement = document.querySelector('.js-url-input') as HTMLInputElement;

const generatedTopicInputElement = document.querySelector('.js-generated-topic-input') as HTMLInputElement;

// const resultDetailsElement = document.querySelector('.js-result-details');

const loadingOverlayElement = document.querySelector('.js-loading-overlay');
const errorPopupElement = document.querySelector('.js-error-popup');

let editor: any;
let imagePrompt: string;
let password: string;
let authKey: string

function init() {
    showPasswordPrompt()
        .then(() => {
            enableApiFlow();
            initCkeditor();
            addGenerateArticleListener();
            addGenerateImageListener();
        });
}

init();

function showPasswordPrompt() {
    return new Promise((resolve, reject) => {
        if (isLocalhost) {
            resolve(true);

            return;
        }

        password = window.prompt('Enter website password:') || '';

        if (password != null && password.trim() !== '') {
            authKey = btoa('username' + ':' + password);

            resolve(password);
        } else {
            showWrongPasswordError();

            reject('Password cannot be empty');
        }
    });
}

function showWrongPasswordError() {
    const refreshMessage = '<h1>Wrong password :(. Please refresh the page and try again.</h1>';

    document.body.innerHTML = refreshMessage;
}

function initCkeditor() {
    // ClassicEditor
    (window as any).ClassicEditor
        .create(editorElement, {
            heading: {
                options: [
                    {
                        model: 'paragraph',
                        view: 'p',
                        title: 'Paragraph',
                    },
                    {
                        model: 'heading1',
                        view: 'h1',
                        title: 'Heading 1',
                    },
                    {
                        model: 'heading2',
                        view: 'h2',
                        title: 'Heading 2',
                    },
                    {
                        model: 'heading3',
                        view: 'h3',
                        title: 'Heading 3',
                    },
                ],
            },
        })
        .then(ckeditor => {
            editor = ckeditor;

            // editor.execute('imageStyle', { value: 'alignRight' });

            // editor.setData('<h1>Heading</h1>')
        })
        .catch(error => {
            console.error(`CKEditor error`, error);
        });
}

function addGenerateArticleListener() {
    generateArticleBtn?.addEventListener('click', () => {
        const url = urlInputElement?.value;

        if (!url) {
            alert('Enter URL please :)');

            return;
        }

        generateArticleBtn?.classList.add('is-loading');
        loadingOverlayElement?.classList.add('is-visible');

        sendRequest(`${apiUrl}/auto-flow`, { url })
            .then(response => {
                const articleContent = response?.article;

                imagePrompt = response?.imagesPrompt;

                if (articleContent) {
                    editor.data.set(editor.getData() + articleContent);
                }
            })
            .catch(error => {
                console.error(`Auto flow error`, error);
            })
            .finally(() => {
                generateArticleBtn?.classList.remove('is-loading');
                loadingOverlayElement?.classList.remove('is-visible');
            });
    });
}

function addGenerateImageListener() {
    generateImageBtn?.addEventListener('click', () => {
        // editor.data.set(editor.getData() + '<img alt="dupa" src="https://replicate.delivery/pbxt/GQiXHMBBhtYZORrGV8qn2HAr6snpF0V4XpfvWacgswjFE0NIA/out-0.png">');

        if (!imagePrompt) {
            alert('Please generate an article first :)');

            return;
        }

        generateImageBtn?.classList.add('is-loading');

        sendRequest(`${apiUrl}/v1/get-image`, { prompt: imagePrompt })
            .then(response => {
                const htmlImages = response?.html?.join('');

                if (htmlImages) {
                    editor.data.set(htmlImages + editor.getData());
                    editor.execute('imageStyle', { value: 'alignRight' });
                }
            })
            .catch(error => {
                console.error(`Generate images error`, error);
            })
            .finally(() => {
                generateImageBtn?.classList.remove('is-loading');
            });
    });
}

async function sendRequest(endpoint: string, params: any, method = 'POST') {
    try {
        const response = await fetch(endpoint, {
            method,
            // credentials: 'include', // to allow cross-origin cookies
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Basic ' + authKey,
            },
            body: JSON.stringify(params),
        });

        if (!response.ok) {
            if (response.status === HttpCode.UNAUTHORIZED) {
                showWrongPasswordError();
            }

            throw new Error(`Status: ${response.status}`);
        }

        const data = await response.json();

        return data;
    } catch (error) {
        // generateImageBtn?.classList.add('has-error');
        // generateArticleBtn?.classList.add('has-error');

        // setTimeout(() => {
        //     generateImageBtn?.classList.remove('has-error');
        //     generateArticleBtn?.classList.remove('has-error');
        // }, 2500);

        showErrorPopup(error);

        throw new Error(`Request error. ${error}`);
    }
}

function showErrorPopup(message: string | any) {
    const visibleClass = "is-visible";

    if (errorPopupElement) {
        errorPopupElement.textContent = message || 'Error occured';
        errorPopupElement.classList.add(visibleClass);

        setTimeout(() => {
            errorPopupElement?.classList.remove(visibleClass);
        }, 45 * 1000);
    }
}

let codeEditor: EditorView;

function enableApiFlow() {
    initCodeEditor();
    addApiFlowBtnListener();
}

// const developmentData: GenerateReqParams = {
//     url: "https://www.hemiro.com/",
//     topic: "How to Create High-Quality, Original Content with AI Technology",
//     mainKeyword: "AI content for SEO",
//     keywords: [
//         'content generator',
//         'SEO article generation',
//         'AI content creator',
//         'unique seo posts',
//     ],
//     keywordsLocation: "United States",
//     outline: `Introduction
// What is AI content generation?
// Why use AI technology for content creation?
// How to create high-quality, original content with AI technology
// Step 1: Define your target audience and topic
// Step 2: Conduct keyword research
// Step 3: Choose an AI content generation tool
// Step 4: Customize your content settings
// Step 5: Review and edit your generated content
// Why is it worth using Hemiro?
// Conclusion`,
//     // outputLength: ArticleLength.Short,
//     outputLanguage: "en",
//     postbackUrl: `${apiUrl}/v1/testing/webhook`,
// };

const developmentData: GenerateReqParams = {
    url: "https://piperinox.com/",
    topic: "Effective Weight Loss Supporting Product with Piperine",
    mainKeyword: "Piperinox",
    keywords: [
        "piperinox amazon",
        "piperinox avis",
        "piperinox avis medical",
        "piperinox cena",
        "piperinox en pharmacie",
        "piperinox fiyat",
        "piperinox forum",
        "piperinox kullananlar",
        "piperinox opinie",
        "piperinox opinie forum",
    ],
    keywordsLocation: "United Kingdom",
    outline: `1. What is Piperinox?
2. How to Use Piperinox for the Best Results
3. How Piperinox Can Help You Lose Weight
4. Vegetarian DRcaps Capsules
5. Safe and Effective Weight Loss Supplement
6. Suppresses Appetite Hormones
7. Increases Endurance and Energy Levels`,
    // outputLength: ArticleLength.Short,
    outputLanguage: "en",
    postbackUrl: `${apiUrl}/v1/testing/webhook`,
};

const productionData: GenerateReqParams = {
    url: "https://www.shopify.com/uk/blog",
    topic: "5 Ways to Boost Your Online Store Sales Today (Without Spending a Dime)",
    mainKeyword: "Shopify",
    keywords: [
        "shopify",
        "shopify account",
        "shopify api",
        "shopify api documentation",
        "shopify app",
        "shopify apps",
        "shopify community",
        "shopify customer support",
        "shopify domain",
        "shopify earnings",
    ],
    keywordsLocation: "United States",
    outline: `1. Take advantage of search engine optimization (SEO).
2. Use social media platforms to promote your store.
3. Use aggressive pricing strategies.
4. Use email marketing to promote your store.
5. Use Shopify's ecommerce community to stay up-to-date on the latest trends.`,
    // outputLength: ArticleLength.Short,
    outputLanguage: "en",
    postbackUrl: `${apiUrl}/v1/testing/webhook`,
};

function initCodeEditor() {
    const contentEditorJsonData: GenerateReqParams = isLocalhost ? developmentData : productionData;

    const jsonString = JSON.stringify(contentEditorJsonData, (key, value) => {
        if (typeof value === "string") {
            return value.replace(/"/g, '\"');
        }

        return value;
    }, 2);

    const contentEditorInitialText =
        `curl --request POST \\
     --url "${apiUrl}/v1/generate" \\
     --header "Authorization: Bearer ${authKey || 'YOUR_API_KEY'}" \\
     --header "Content-Type: application/json" \\
     --data-raw \\
'${jsonString}'`;

    const codeEditorElement = document.querySelector('.js-code-editor')!;

    codeEditor = new EditorView({
        doc: contentEditorInitialText,
        extensions: [
            basicSetup,
            jsonColors(),
            // [javascriptColors({ typescript: true })],
        ],
        parent: codeEditorElement,
    });
}

function addApiFlowBtnListener() {
    apiFlowBtn?.addEventListener('click', () => {
        const codeEditorValue = codeEditor.state.doc.toString();
        const dataRegex = /--data-raw\s+\\\n'{\s*([\s\S]*?)\s*}'\s*/;
        const regextMatch = codeEditorValue.match(dataRegex);
        let params: GenerateReqParams;

        if (!regextMatch) {
            console.error('Invalid curl data format', codeEditorValue);
            alert('Invalid curl data format. Please fix the command :)')

            return;
        }

        const jsonString = '{' + regextMatch[1]?.replace(/\\\n/g, '\n') + '}';

        try {
            params = JSON.parse(jsonString);
        } catch (error) {
            console.error('Invalid api flow request params', jsonString);

            return;
        }

        apiFlowBtn?.classList.add('is-loading');
        loadingOverlayElement?.classList.add('is-visible');

        // console.log('request', params)

        sendRequest(`${apiUrl}/v1/generate`, params)
            .then((response: GenerateArticleResDTO) => {
                console.log('Api request response: ', response);
                
                imagePrompt = response?.imagePrompt;

                const { content, topic, wordCount, ...rest } = response;

                if (topic) {
                    generatedTopicInputElement.value = topic;
                }

                if (content) {
                    editor.data.set(editor.getData() + content);
                }

                // resultDetailsElement!.innerHTML = JSON.stringify(details, null, 2).replace(/":\{/g, '":\n{');
            })
            .catch(error => {
                console.error(`Api flow request error:`, error);
            })
            .finally(() => {
                apiFlowBtn?.classList.remove('is-loading');
                loadingOverlayElement?.classList.remove('is-visible');
            });
    });
}
